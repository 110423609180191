import React from "react";
import { ImArrowRight } from "react-icons/im";
import { Link } from "react-scroll";

const About = () => {
  return (
    <div
      name="about"
      className="h-fit pt-20 md:h-screen md:pt-0 w-full bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-4">
          <p className="text-4xl font-bold inline border-b-4 border-amber-400">
            About me
          </p>
        </div>
        <p className="text-xl mt-10">
          Ever since I was a child, I've been drawn to the world of software
          development. It all started when I stumbled upon the Unity engine.
          Fascinated by its potential, I began experimenting with the engine and
          creating small games on my own. It wasn't long before I was hooked on
          programming, and I started learning C# to take my game development
          skills to the next level. To this day, I still remember the excitement
          of seeing my creations come to life on the screen, and it's what
          inspired me to pursue a career in software development.
        </p>
        <p className="text-xl mt-10 pb-4">
          I have a deep passion for technology and enjoy exploring it in all its
          forms. One of my greatest pleasures is learning about new languages &
          frameworks and expanding my knowledge to stay up-to-date with the
          latest advancements. Moreover, I value teamwork and collaboration as I
          believe working together with others can lead to better outcomes and
          more innovative solutions.
        </p>
        <div className="flex justify-center">
          <Link
            to="skills"
            smooth
            duration={800}
            className="group text-white w-fit px-6 py-3 my-2 hidden md:flex items-center rounded-md bg-gradient-to-r from-amber-600 to-amber-400 cursor-pointer"
          >
            My skills
            <span className="ml-3 group-hover:rotate-90 duration-300">
              <ImArrowRight />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import HeroImage from "../assets/photo.png";
import { ImArrowRight } from "react-icons/im";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="home"
      className="h-fit pt-20 md:h-screen md:pt-0 w-full bg-gradient-to-b from-black via-black to-gray-800"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 lg:flex-row">
        <div>
          <img
            src={HeroImage}
            alt="my face"
            className="rounded-2xl mx-auto w-2/3 mt-28 lg:mt-0"
          />
        </div>
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl md:text-6xl font-bold text-amber-500">
            I'm a Software Developer
          </h2>
          <p className="text-gray-500 py-4 max-w-md">
            Highly motivated software developer with over 3 years of programming
            experience. Always eager to expand his knowledge and skills in the
            ever-evolving field of software development. Enjoys working with
            others to achieve common goals and find creative solutions to
            complex problems.
          </p>
          <div>
            <Link
              to="about"
              smooth
              duration={800}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-amber-600 to-amber-400 cursor-pointer"
            >
              About me
              <span className="ml-3 group-hover:rotate-90 duration-300">
                <ImArrowRight />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
